import IPokemon from "../interfaces/IPokemon";

function PokemonCard(props: {
  pokemon: IPokemon,
  extension: string,
  unlocked: boolean,
  click: () => void
}) {
  return (
    <div key={props.pokemon.id} className="">
      <div
        className="cursor-pointer"
        onClick={props.click}
      >
        <img
          src={`/img/pokemon-tcg-pocket/${props.extension}/image_${props.pokemon.id}.png`}
          className={props.unlocked ? "" : "opacity-35"}
          alt={props.pokemon.names[0]}
        />
      </div>
      {
        (props.pokemon.boosters.map((booster) => (
          <img src={"/img/assets/" + booster.toLowerCase() + ".png"} className="hidden md:inline"
               id={props.extension + "_" + props.pokemon.id + "_" + booster.toLowerCase()}
               alt={booster} />
        )))
      }
    </div>
  );
}

export default PokemonCard;