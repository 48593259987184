import {useEffect, useState} from "react";

import pocketdex from "../api/pokemon-tcg-pocket.json";
import IExtension from "../interfaces/IExtension";
import IQuest from "../interfaces/IQuest";

import IPokemon from "../interfaces/IPokemon";
import IPlayerCards from "../interfaces/IPlayerCards";
import IFilter from "../interfaces/IFilter";

import {ACTUAL_EXTENSION} from "./Filters";
import PokemonCard from "./PokemonCard";

const CardCollection = (props: {
  playerCards: IPlayerCards[],
  filter: IFilter,
  onCardClick: (event: IPlayerCards) => void
}) => {
  const [extensions, setExtensions] = useState<IExtension[]>([]);
  const [quests, setQuests] = useState<IQuest[]>([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const loadData = () => {
      // Loading extensions with the associated cards
      const loadedExtensions = pocketdex.extensions.map(it => it);
      setExtensions(loadedExtensions);

      // Loading hidden quests
      const loadedQuests = pocketdex.quests.map(it => it);
      setQuests(loadedQuests);
    };

    loadData();
  }, []);

  useEffect(() => {
    setLoaded(true);
  }, [extensions, quests]);

  if (!loaded) {
    return (<h1 className="text-xl text-center">Loading...</h1>);
  }

  const filteredExtension: IExtension = extensions.find(it => it.id === (props.filter.extension != null ? props.filter.extension : ACTUAL_EXTENSION))!!;
  const filteredCards: number[] = props.playerCards.find(it => it.extension === filteredExtension.id)?.cards || [];

  const canDisplayCard = (card: IPokemon, related: boolean): boolean => {
    const boosterFilter = props.filter.boosters?.filter((item) => !item.includes("-")) || [];
    const rarityFilter = props.filter.rarities?.filter((item) => item.includes("-")) || [];
    const generationFilter = props.filter.generation || 0;

    const matchesRarity = rarityFilter.length === 0 || rarityFilter.includes(card.rarity);
    const matchesBooster = boosterFilter.length === 0 || card.boosters.some((booster) => boosterFilter.includes(booster));
    const matchesGeneration = generationFilter === 0 || (card.generation === generationFilter && card.type === "pokemon");

    if (!related) {
      const isObtained = filteredCards.includes(card.id) || false;
      const matchesObtainedFilter = props.filter.obtained === true ? isObtained : true;
      const matchesLockedFilter = props.filter.obtained === false ? !isObtained : true;

      return (matchesRarity && matchesBooster && matchesGeneration && matchesObtainedFilter && matchesLockedFilter);
    }

    return (matchesRarity && matchesBooster && matchesGeneration);
  }

  const formatQuestName = (name: string) => {
    const regex = /\[img:([a-z-]+)]/;
    const replacement = '<img src="/img/assets/$1.png" width="10%" class="inline" alt="$1">';

    return name.replace(regex, replacement);
  }


  if (props.filter.quest != null) {
    const quest = quests.find(it => it.name === props.filter.quest)!!;

    const questPokemons: IPokemon[] = [];
    const questExtension: IExtension = extensions.find(it => it.id === quest.extension)!!;

    questExtension.cards.forEach(card => {
      if (quest.cards.includes(card.id)) {
        questPokemons.push(card);
      }
    });

    return (
      <>
        <h3 className="text-lg font-semibold"
            dangerouslySetInnerHTML={{__html: formatQuestName(quest.name) + " - " + questExtension.name}}
        />
        <div className="grid grid-cols-5 gap-1 md:gap-2 mb-3">
          {questPokemons.map(card => {
            const questRelatedPokemons: IPokemon[] = [];
            if (quest.allowRelated) {
              card.related.forEach((cardId) => {
                questRelatedPokemons.push(questExtension.cards.find((c) => c.id === cardId)!!);
              });
            }

            const set = new Set(filteredCards || []);
            const isObtained = questRelatedPokemons.filter(it => set.has(it.id)).length > 0 || filteredCards.includes(card.id) || false;
            const matchesObtainedFilter = props.filter.obtained === true ? isObtained : true;
            const matchesLockedFilter = props.filter.obtained === false ? !isObtained : true;

            return (canDisplayCard(card, true) && matchesObtainedFilter && matchesLockedFilter) ? (
              <div className="card">
                <PokemonCard
                  pokemon={card}
                  extension={filteredExtension.id}
                  unlocked={filteredCards.includes(card.id) || false}
                  click={() => props.onCardClick({extension: filteredExtension.id, cards: [card.id]})}
                />
                {
                  questRelatedPokemons.map((card) => (
                    <img
                      src={`/img/pokemon-tcg-pocket/${filteredExtension.id}/image_${card.id}.png`}
                      className={(filteredCards.includes(card.id) ? "" : "opacity-25") + " w-1/5 inline mt-1 mx-1 cursor-pointer"}
                      alt={card.names[0]}
                      onClick={() => props.onCardClick({extension: filteredExtension.id, cards: [card.id]})}
                    />
                  ))
                }
              </div>
            ) : null;
          })}
        </div>
      </>
    );
  }

  return (
    <>
      <h3 className="text-lg font-semibold">{filteredExtension.name}</h3>
      <div className="grid grid-cols-5 gap-1 md:gap-2 mb-3">
        {filteredExtension.cards.map(card => {
          const isObtained = filteredCards.includes(card.id) || false;

          return (canDisplayCard(card, false)) ? (
            <div className="card">
              <PokemonCard
                pokemon={card}
                extension={filteredExtension.id}
                unlocked={isObtained}
                click={() => props.onCardClick({extension: filteredExtension.id, cards: [card.id]})}
              />
            </div>
          ) : null;
        })}
      </div>
    </>
  );
};

export default CardCollection;