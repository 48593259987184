import { useEffect, useState } from "react";
import { Buffer } from "buffer";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import CardCollection from "./views/CardCollection";
import Statistics from "./views/Statistics";
import Filters from "./views/Filters";

import IPlayerCards from "./interfaces/IPlayerCards";
import IFilter from "./interfaces/IFilter";

const App = () => {
  const [playerCards, setPlayerCards] = useState<IPlayerCards[]>([]);
  const [filter, setFilter] = useState<IFilter>({});
  const [forceMenu, setForceMenu] = useState(false);
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const storedCards = JSON.parse(localStorage.getItem("cards") as string) || [];
    setPlayerCards(storedCards);

    if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      setDarkMode(true);
    }
  }, []);

  const handleFilterChange = (event: IFilter) => {
    const currentFilter: IFilter = {};

    if (event.extension !== null) currentFilter.extension = event.extension;
    if (event.boosters !== null) currentFilter.boosters = event.boosters;
    if (event.rarities !== null) currentFilter.rarities = event.rarities;
    if (event.generation !== null) currentFilter.generation = event.generation;
    if (event.quest !== null) currentFilter.quest = event.quest;
    if (event.obtained !== null) currentFilter.obtained = event.obtained;

    setFilter(currentFilter);
  };

  const handleCardClick = (event: IPlayerCards) => {
    const currentPlayerCards = playerCards;

    const extension = playerCards.find(it => it.extension === event.extension);
    if (extension != null) {
      if (extension.cards.includes(event.cards[0])) {
        extension.cards = extension.cards.filter(it => it !== event.cards[0]);
      } else {
        extension.cards.push(event.cards[0]);
      }

      const updatedPlayerCards = currentPlayerCards.filter(it => it.extension !== event.extension);
      updatedPlayerCards.push(extension);
      setPlayerCards(updatedPlayerCards);
    } else {
      currentPlayerCards.push(event);
      setPlayerCards(currentPlayerCards);
    }

    localStorage.setItem("cards", JSON.stringify(playerCards));
  };

  const exportDeck = () => {
    const base64Text = Buffer.from(localStorage.getItem("cards") as string).toString("base64");
    navigator.clipboard.writeText(base64Text)
      .then(() => toast("Deck copié au presse-papier !"));
  };

  const importDeck = () => {
    const base64Text = prompt("Collez le contenu exporté depuis votre autre appareil :");
    if (base64Text == null || base64Text === "")
      return;

    const decodedDeck: { extension: string, cards: number[] }[] = JSON.parse(atob(base64Text));
    setPlayerCards(decodedDeck);
    localStorage.setItem("cards", JSON.stringify(decodedDeck));
  };

  return (
    <div className={"flex min-h-screen" + (darkMode ? " dark" : "")}>
      <div className={forceMenu ? "w-full menu-visible z-30" : "w-1/5 menu-visible invisible"}>
        <div className="overflow-y-auto h-[92%] scrollbar">
          <Statistics playerCards={playerCards} filter={filter} />
          <Filters playerCards={playerCards} onFilterChange={handleFilterChange} />
        </div>

        <div className="fixed bottom-0 left-0 p-4 grid grid-cols-2 gap-4" id="settings">
          <button onClick={() => exportDeck()}>Exporter</button>
          <button onClick={() => importDeck()}>Importer</button>
        </div>

      </div>

      <div className={forceMenu ? "hidden" : "main"}>
        <CardCollection playerCards={playerCards} filter={filter} onCardClick={handleCardClick} />
      </div>

      <button
        onClick={() => {
          setForceMenu(!forceMenu);
        }}
        className="fixed cursor-pointer rounded-full bg-gray-200 p-2 z-50 md:hidden bottom-2 right-2"
      >⚙️
      </button>

      <ToastContainer />
    </div>
  );
};

export default App;