import pocketdex from "../api/pokemon-tcg-pocket.json";
import IPlayerCards from "../interfaces/IPlayerCards";
import IFilter from "../interfaces/IFilter";
import {ACTUAL_EXTENSION} from "./Filters";

const Statistics = (props: { playerCards: IPlayerCards[], filter: IFilter }) => {
  const rarities: { key: string, symbol: string }[] = [
    {key: "1-diamant", symbol: "♦"},
    {key: "2-diamant", symbol: "♦♦"},
    {key: "3-diamant", symbol: "♦♦♦"},
    {key: "4-diamant", symbol: "♦♦♦♦"},
    {key: "1-etoile", symbol: "⭐"},
    {key: "2-etoile", symbol: "⭐⭐"},
    {key: "3-etoile", symbol: "⭐⭐⭐"},
  ];

  const extension =
    props.filter.extension != null
      ? pocketdex.extensions.find(it => it.id === props.filter.extension)!!
      : pocketdex.extensions.find(it => it.id === ACTUAL_EXTENSION)!!;
  const playerCards = props.playerCards.find(it => it.extension === extension.id)?.cards || [];

  const extensionBoosters = () => {
    switch (extension.id) {
      case "puissance-genetique":
        return ["Dracaufeu", "Mewtwo", "Pikachu"];

      default:
        return [];
    }
  }

  const specialQuestCards = (total: boolean): { name: string, cards: number } => {
    let cardAmount = 0;
    let questName = "";
    let cardNames: string[] = [];

    switch (extension.id) {
      case "puissance-genetique": {
        questName = "Pokédex de Kanto";
        extension.cards.forEach(card => {
          if (card.generation === 1 && card.type === "pokemon" && card.id !== 283) {
            const name = card.names[0].replace("EX", "").trim();
            if (((!total && playerCards.includes(card.id)) || total) && !cardNames.includes(name)) {
              cardNames.push(name);
              cardAmount++;
            }
          }
        });
        break;
      }
    }

    return {name: questName, cards: cardAmount};
  }

  const cardsPerBooster = (booster: string, total: boolean, rarity?: string) => {
    let cardAmount = 0;
    extension.cards.forEach(card => {
      if (
        card.boosters.includes(booster) &&
        (total || playerCards.includes(card.id)) &&
        (rarity != null ? card.rarity === rarity : true)
      ) {
        cardAmount++;
      }
    });

    return cardAmount;
  }

  const requiredPoints = () => {
    let points = 0;
    extension.cards.forEach(card => {
      if (!playerCards.includes(card.id)) {
        points += card.points;
      }
    });

    return points;
  }

  const SpecialQuest = () => {
    const quest = specialQuestCards(false);
    const questTotal = specialQuestCards(true);

    if (questTotal.cards === 0) {
      return null;
    }

    return (
      <p>{quest.name} : <code>{quest.cards} / {questTotal.cards}</code></p>
    )
  }

  const DetailedBooster = () => {
    const boosters = extensionBoosters();
    if (boosters.length === 0) {
      return null;
    }

    return (
      <p className="mt-3">
        Cartes par booster :
        <ul>
          {
            extensionBoosters().map(booster => (
              <div className="relative group">
                <li className="ml-5 list-disc cursor-pointer hover:font-semibold">
                  {booster} : <code>{cardsPerBooster(booster, false)} / {cardsPerBooster(booster, true)}</code>
                </li>
                <div className="details">
                  <h5 className="text-sm font-semibold ml-3">Détail par rareté :</h5>
                  <ul>
                    {
                      rarities.map(rarity => (
                        <li className="ml-10">
                          <code>{cardsPerBooster(booster, false, rarity.key)} / {cardsPerBooster(booster, true, rarity.key)}</code> {rarity.symbol}
                        </li>
                      ))
                    }
                  </ul>
                </div>
              </div>
            ))
          }
        </ul>
      </p>
    )
  }

  return (
    <>
      <h2 className="text-lg font-semibold">Statistiques</h2>
      <h3 className="text-sm mb-2">{extension.name}</h3>

      <p>Cartes obtenues : <code>{playerCards.length} / {extension.cards.length}</code></p>
      <SpecialQuest/>
      <p>Points nécessaires : <code>{requiredPoints()}</code></p>

      <DetailedBooster/>
    </>
  )
};

export default Statistics;